.form-input {
  @apply rounded-sm bg-gray-100 border-gray-100 text-black py-2 text-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none focus:shadow-none focus:border-transparent focus:border-transparent;
}

.form-input.text-field {
  @apply h-10;
}

.form-input.input-clear {
  @apply bg-white;
}

.primary-button {
  @apply bg-brand-default text-white px-2 py-2 cursor-pointer;
}

.h1 {
  @apply text-3xl font-bold;
}

.h2 {
  @apply text-2xl font-semibold;
}

.h3 {
  @apply text-lg font-medium;
}

.label {
  @apply text-base font-medium text-gray-900 mb-1;
}

.react-responsive-modal-modal {
  overflow-y: initial !important;
}
