.navbar {
  height: 100vh;
  width: 200px;
}

.dashboard {
  width: calc(100vw - 160px);
}

@media screen and (max-width: 1023px) {
  .dashboard {
    width: 100%;
  }
}

.main-content {
  min-height: calc(100vh - 130px);
}

.topbar {
  width: calc(100vw - 160px);
}
